<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="text-h3 font-weight-bold mb-3">
          Profile Settings
        </h1>
        <v-card class="px-4">
          <v-card-text>
            <v-form
              ref="changePasswordForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.current_password"
                    :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showCurrentPassword ? 'text' : 'password'"
                    name="password"
                    label="Current Password *"
                    hint="At least 8 characters"
                    @click:append="showCurrentPassword = !showCurrentPassword"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.new_password"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="showNewPassword ? 'text' : 'password'"
                    name="password"
                    label="Password *"
                    hint="At least 8 characters"
                    @click:append="showNewPassword = !showNewPassword"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="formData.new_password_confirmation"
                    :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.matches]"
                    :type="showPasswordConfirmation ? 'text' : 'password'"
                    name="password-confirmation"
                    label="Confirm Password *"
                    block
                    @click:append="showPasswordConfirmation = !showPasswordConfirmation"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer />
      <v-col
        class="d-flex ml-auto xsm"
        cols="12"
        sm="3"
      >
        <v-btn
          x-large
          block
          :disabled="!valid"
          color="success"
          @click="submit"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'ProfileSettings',
    computed: {
      ...mapGetters({
        token: 'user/getAccessToken',
        user: 'user/getUser',
      }),
      selectedCountry () {
        return this.countries.find(r => r.code === this.user.country)
      },
      selectedCity () {
        return this.cities.find(r => r.code === this.user.city)
      },
    },
    data () {
      return {
        valid: false,
        showCurrentPassword: false,
        showNewPassword: false,
        showPasswordConfirmation: false,
        formData: {
          current_password: '',
          new_password: '',
          new_password_confirmation: '',
        },
        rules: {
          required: value => !!value || 'Required',
          matches: value => this.formData.new_password === value || 'Password and confirmation must match',
          min: v => (v && v.length >= 8) || 'Min 8 characters',
        },
      }
    },
    methods: {
      ...mapActions({
        changePassword: 'user/changePassword',
        pushMessage: 'ui/pushMessage',
      }),
      submit () {
        if (this.$refs.changePasswordForm.validate()) {
          this.changePassword({
            token: this.token,
            payload: this.formData,
          })
            .then(result => {
              this.pushMessage({
                status: result.status,
                message: result.message,
                delay: 2000,
              })
              this.$router.push('/')
            })
        }
      },
    },
  }
</script>
